import React, { useState, useEffect } from 'react';
import { Col, Row, InputGroup, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faEnvelope, faLocationDot, faPhone, faSpinner } from '@fortawesome/free-solid-svg-icons';

$(window).scroll(function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 400) {
        $('#scrollUp').fadeIn(500);
    } else {
        $('#scrollUp').fadeOut(500);
    }
});

const Footer = () => {
    const [info, setInfo] = useState({
        id: null,
        shop_name: null,
        sdt: null,
        email: null,
        address: null,
        logo: null,
        script_footer: null,
        script_header: null,
        slogan: null,
        facebook: null,
        zalo: null,
    });

    const [email, setEmail] = useState('');
    const [isLoadSentEmail, setIsLoadSentEmail] = useState(false);

    useEffect(() => {
        getInfoShop();
    }, []);

    const getInfoShop = () => {
        axios.get(`/getInfoShop`).then((res) => {
            if (res.status === 200) {
                setInfo(res.data.setting);
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const scrollWin = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }


    const handleSubmitEmail = (e) => {
        e.preventDefault();

        let formData = new FormData();

        formData.append('contact_email', email);

        setIsLoadSentEmail(true);

        axios.post(`/contactCreate`, formData).then((res) => {
            if (res.status === 200) {
                if (res.data.errCode === 0) {
                    toast(res.data.message, { type: 'success' });
                } else {
                    let errMessages = res.data.messages.contact_email;

                    if (errMessages.length > 0) {
                        errMessages.map((item, i) => {
                            toast(item, { type: 'error' });
                            return item;
                        });
                    }
                }
            }
            setIsLoadSentEmail(false);
        }).catch((error) => {
            toast(error.message, { type: 'error' });
            setIsLoadSentEmail(false);
        });
    }

    return (

        <footer >
            <div >
                <Row className='category mb-0 py-0 ms-0 me-0'>
                    <div className='d-flex justify-content-center flex-wrap flex-md-nowrap' >
                        <div className='me-md-2 me-0 d-flex align-items-center mb-2 mb-md-0'>
                            <FontAwesomeIcon icon={faEnvelope} className='me-2' />
                            <span className='mail align-middle' > ĐĂNG KÝ NHẬN BẢN TIN</span>
                        </div>

                        <Form onSubmit={handleSubmitEmail}>
                            <InputGroup className="mb-0 py-0  search-input align-items-center" onChange={(e) => setEmail(e.target.value)} value={email} required>
                                <Form.Control className='noboder' placeholder="Nhập địa chỉ Email..." />
                                <button type="submit" className='bnt_search_footer align-middle' disabled={isLoadSentEmail ? true : false} >
                                    {isLoadSentEmail ? <><FontAwesomeIcon icon={faSpinner} spinPulse /> Đang gửi</> : <>Đăng ký</>}
                                </button>
                            </InputGroup>
                        </Form>
                    </div>
                </Row>

                <Row >
                    <Col md={4} className='px-4' style={{ borderRight: "1px solid  rgb(140 140 140)" }}>
                        <h3 className='title d-flex'>
                            {info.logo !== null ?
                                <img className='footer-logo' src={`${process.env.REACT_APP_BACKEND_URL}/images/logo/${info.logo}`} alt="logo Mỹ Kat Organic" />
                                : null
                            }
                            <span className='footer_title_name'>{info.shop_name}</span>
                        </h3>
                        {info.slogan !== null ?
                            <div dangerouslySetInnerHTML={{ __html: info.slogan }}></div>
                            : null
                        }

                        <div className='text-center'>
                            <Link target="_blank" to={info.facebook !== null ? info.facebook : '#!'} title="Facebook">
                                <img alt="Facebook" src="/assets/images/icon/facebook-icon.png" />
                            </Link>

                            <Link target="_blank" to={`https://zalo.me/${info.zalo}`} title="Zalo">
                                <img alt="Zalo" src="/assets/images/icon/zalo-icon.png" />
                            </Link>

                            <Link to="#!" title="Instagram">
                                <img alt="Instagram" src="/assets/images/icon/Insta-icon.png" />
                            </Link>

                            <Link to="#!" title="Youtube">
                                <img alt="Youtube" src="/assets/images/icon/Youtube-icon.png" />
                            </Link>
                        </div>


                    </Col>
                    <Col md={8} className='footer_home px-4'>
                        <Row>
                            <Col md={4}>
                                <span className='footer_title'>   Dịch vụ</span>

                                <ul>
                                    {/* <li>
                                        <Link to={'/bai-viet/dieu-khoan-su-dung'}>
                                            Điều Khoản Sử Dụng
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to={'/bai-viet/dieu-khoan-va-dieu-kien'}>
                                            Điều Khoản & Điều Kiện
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/bai-viet/chinh-sach-bao-mat'}>
                                            Chính Sách Bảo Mật
                                        </Link>
                                    </li>

                                </ul>
                            </Col>
                            <Col md={4}>
                                <span className='footer_title'>Hỗ trợ</span>
                                <ul>
                                    <li>
                                        <Link to={'/bai-viet/chinh-sach-doi-tra-hoan-tien'}>
                                            Chính Sách Đổi Trả & Hoàn Tiền
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/bai-viet/quy-dinh-viet-binh-luan'}>
                                            Quy Định Viết Bình Luận
                                        </Link>
                                    </li>
                                    {/* <li>
                                        Chính sách bảo hành - bồi hoàn
                                    </li>
                                    <li>
                                        Chính sách vận chuyển
                                    </li>
                                    <li>
                                        Chính sách khách sỉ
                                    </li>
                                    <li>
                                        Phương thức thanh toán và xuất HĐ
                                    </li> */}
                                </ul>
                            </Col>
                            <Col md={4}>
                                <span className='footer_title'> Tài khoản của tôi</span>
                                <ul>
                                    <li>
                                        <Link to={`${process.env.REACT_APP_BACKEND_URL}/login/`} target='_blank'>
                                            Đăng nhập
                                        </Link>
                                    </li>
                                    {/* <li>
                                        Thay đổi địa chỉ khách hàng
                                    </li>
                                    <li>
                                        Chi tiết tài khoản
                                    </li>
                                    <li>
                                        Lịch sử mua hàng
                                    </li> */}
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <div className='footer_title py-0'>Liên hệ</div>
                            <Col md={4} >
                                <FontAwesomeIcon icon={faLocationDot} />{info.address !== null ? <span style={{ padding: '5px' }} title='Địa chỉ' >{info.address}</span> : null}
                                <br />
                                <FontAwesomeIcon icon={faEnvelope} />{info.email !== null ? <span style={{ padding: '5px' }} title='Email' >{info.email}</span> : null}
                                <br />
                                <Link to={info.sdt !== null ? `tel:${info.sdt}` : `#`} title='Số điện thoại'>
                                    <FontAwesomeIcon icon={faPhone} />{info.sdt !== null ? <span style={{ padding: '5px' }}>{info.sdt}</span> : null}
                                </Link>
                            </Col>
                            <Col md={4} dangerouslySetInnerHTML={{
                                __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3929.096880660681!2d105.78582200000001!3d10.008856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDAwJzMxLjkiTiAxMDXCsDQ3JzA5LjAiRQ!5e0!3m2!1svi!2s!4v1711510228188!5m2!1svi!2s"
                                    width="100%" height="110" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
                            }}>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className='text-center'>
                    <Link className='text-dark' to={`https://thietkeweb.katec.vn/`} target='_blank'>Design by Katec</Link>
                </Row>
            </div >

            <div id="scrollUp" onClick={scrollWin} title="Lên đầu trang">
                <FontAwesomeIcon icon={faAngleUp} size='2xl' style={{ color: '#FFF', backgroundColor: 'none' }} />
            </div>
        </footer >
    );
}

export default Footer;