import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const [categorys, setCategorys] = useState([]);
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        getCategorys();
        getTopics();
    }, []);

    const getCategorys = () => {
        axios.get("/getCategorysToHome").then((res) => {
            setCategorys(res.data)
        }).catch((error) => {
        });
    }

    const getTopics = () => {
        axios.get("/getTopics").then((res) => {
            setTopics(res.data.topics);
        }).catch((error) => {
        });
    }

    const categoryItems = () => {
        if (categorys.length > 0) {
            return categorys.map((item, i) => {
                if (item.category_children.length > 0) {
                    let categoryChilds = [];

                    item.category_children.map((itemChild, iChild) => {
                        categoryChilds.push(<NavDropdown.Item as={NavLink} to={`/danh-muc/${itemChild.category_slug}`} key={`categoryChild${iChild}`} onClick={formClose}>{itemChild.category_name}</NavDropdown.Item>)
                        return itemChild;
                    });

                    return (
                        <NavDropdown id={`offcanvasCategoryItem${i}`} title={item.category_name} key={`offcanvasCategoryItem${i}`} >
                            {categoryChilds}
                        </NavDropdown>
                    )
                } else {
                    return (
                        <Nav.Link as={NavLink} id={`offcanvasCategoryItem${i}`} key={`offcanvasCategoryItem${i}`} to={`/danh-muc/${item.category_slug}`} onClick={formClose}>
                            {item.category_name}
                        </Nav.Link>
                    )
                }
            })
        }
    }

    const topicItems = () => {
        if (topics.length > 0) {
            return topics.map((item, i) => {
                return (
                    <Nav.Link as={NavLink} id={`offcanvasTopicItem${i}`} key={`offcanvasTopicItem${i}`} to={`/tin-tuc/${item.topic_slug}`} onClick={formClose}>
                        {item.topic_name}
                    </Nav.Link>
                )
            })
        }
    }

    const formClose = () => {
        const { onHide } = { ...props };
        return onHide();
    }

    return (
        <Navbar id='home-sidebar-menu' expand={false} >
            <Navbar.Offcanvas
                id="offcanvasNavbarCategory"
                aria-labelledby={`offcanvasNavbarLabel-expand`}
                placement="start"
                show={props.show}
                onHide={formClose}
                className="ps-3"
            >
                <Offcanvas.Header closeButton >
                    <Offcanvas.Title as={Link} to={`/`} className='fs-4 fw-bold text-success' onClick={formClose}>
                        Mỹ Kat Organic
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-0'>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link className='menu-item-parent fw-bold text-success' as={NavLink} to={`/danh-muc/`} onClick={formClose}>
                            <FontAwesomeIcon icon={faBars} /> Danh mục sản phẩm
                        </Nav.Link>

                        {categoryItems()}
                    </Nav>

                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link className='menu-item-parent fw-bold text-success' as={NavLink} to={`/tin-tuc/`} onClick={formClose}>
                            <FontAwesomeIcon icon={faBullhorn} />  Tin tức
                        </Nav.Link>
                        {topicItems()}
                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
    )
}

export default Sidebar;