import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../assets/home/css/style.css';
import { Container, NavDropdown, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCart } from "react-use-cart";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../home/Sidebar';

const Navbar = () => {
    const navigate = useNavigate();

    const { totalUniqueItems } = useCart();

    const [visibleCategory, setVisibleCategory] = useState(false);

    const { search } = useParams();
    const [keyword, setKeyword] = useState(search ? search : '');

    const submitSearch = (e) => {
        e.preventDefault();
        setKeyword('');

        navigate(`/danh-muc/tat-ca}/${keyword}`);
    }

    return (
        <header id="header" className="top-head_">
            <Container >
                <Row className='row-header'>
                    <Col lg={4} md={5} sm={4} xs={1} className='company '>
                        <Link to="/" className='companyinfo'>
                            <img className='navbar_logo me-md-1' src="/logo-white.png" alt="logo Mỹ Kat" />
                            <span className='d-none d-sm-block'>Mỹ Kat Organic</span>
                        </Link>
                    </Col>

                    <Col lg={5} md={4} sm={4} xs={9} className='menu'>
                        <div className='row-item-center d-flex align-items-center'>
                            <div className='nh me-1'
                                onClick={() => setVisibleCategory(true)}
                            >
                                <div className='icon_menu'></div>
                                {/* <div className='icon_seemore_gray'></div> */}
                            </div>

                            <Form onSubmit={submitSearch} style={{ width: '100%' }}>
                                <InputGroup className="search-input">
                                    <Form.Control className='noboder' onChange={(e) => setKeyword(e.target.value)} value={keyword}
                                        placeholder="Tìm kiếm sản phẩm..."
                                    />
                                    <button type='submit' className='bnt_search'>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} className='text-white' />
                                    </button>
                                </InputGroup>
                            </Form>
                        </div>
                    </Col>

                    <Col lg={3} md={3} sm={4} xs={2} className='menu ps-0 text-right'>
                        <div className='row-item-right d-flex justify-content-md-end'>
                            <Link to={"/gio-hang"} className='gio_hang me-2'>
                                <span className='icon_nofi_gray'></span>
                                <span className='title'>Giỏ hàng</span>
                                {totalUniqueItems > 0 ?
                                    <span className="cart_number position-absolute translate-middle badge rounded-pill bg-danger">
                                        {totalUniqueItems}
                                    </span>
                                    : null
                                }
                            </Link>


                            <div className='account_home'>
                                <NavDropdown title={<><div className='icon_account_gray'></div> <span className='title'>Tài khoản</span></>} >
                                    <NavDropdown.Item as={Link} to={`${process.env.REACT_APP_BACKEND_URL}/login/`} target='_blank'>Đăng nhập
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to='/dang-ky'>Đăng ký
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >

            <Sidebar show={visibleCategory} onHide={() => setVisibleCategory(false)} />
        </header >
    )
}

export default Navbar;

