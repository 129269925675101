import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, InputGroup, ListGroup, Nav, Row } from "react-bootstrap";
import BreadcrumbView from "../other/HtmlTag/BreadcrumbView";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import './styles.css';
import CurrencyFormat from 'react-currency-format';
import { useCart } from "react-use-cart";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import CartBubble from '../cart/CartBubble';
import IsLoading from '../other/IsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faMinus, faPlus, faStar } from '@fortawesome/free-solid-svg-icons';


const ProductDetails = (props) => {
    const { product_slug } = useParams();
    const [product, setProduct] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const { addItem, items, updateItemQuantity, updateCartMetadata, metadata, cartTotal } = useCart();

    useEffect(() => {
        document.title = product.product_name || "Sản phẩm";

        window.scrollTo({ top: 0, behavior: 'smooth' });

        getProduct(product_slug);

    }, [product_slug]);

    const getProduct = (productSlug) => {
        setIsLoad(true);
        axios.get(`/getProduct/${productSlug}`).then((res) => {
            if (res.status === 200) {
                setProduct(res.data);
            }
            setIsLoad(false);
        }).catch((error) => {
            setIsLoad(false);
            
        });
    }

    const addToCart = (data) => {
        toast('Đã thêm vào giỏ hàng.', { type: 'success', autoClose: 500 });

        if (!metadata.customer_name || !metadata.discount_code) {
            updateCartMetadata({
                customer_name: '',
                customer_phone: '',
                customer_email: '',
                province_id: '',
                district_id: '',
                ward_id: '',
                customer_address: '',
                contract_desc: '',
                feeshipping: 0,
                discount_code: '',
                discount_money: 0,
                discount_type: '',
                discount_value: '',
                totalMoney: cartTotal,
                error_list: [],
            });
        }

        return addItem(data);
    }

    const productInfo = () => {
        const productDetails = product.product_detail ? product.product_detail : [];

        let itemProDt = [];

        if (productDetails.length > 0) {
            productDetails.map((itemDt, iDt) => {
                const price = parseInt(itemDt.product_price_sale) > 0 ? parseInt(itemDt.product_price_sale) : parseInt(itemDt.product_price);
                const price_old = parseInt(itemDt.product_price_sale) > 0 ? parseInt(itemDt.product_price) : 0;
                let info = '';

                let cartItem = items.filter((itemCart, i) => itemCart.id === itemDt.id);

                let quantity = cartItem.length > 0 ? cartItem[0].quantity : 0;

                if (itemDt.info.length > 0) {
                    itemDt.info.map((itemIf, iIf) => {
                        let firstchar = iIf === 0 ? '' : ' - ';
                        info = info + firstchar + itemIf.info_name + ' ' + itemIf.info_dt_name;
                        return itemIf
                    });
                }

                itemProDt.push(
                    <ListGroup.Item key={`itemProDt${iDt}`}>
                        <Row>
                            <Col md={6}>
                                {itemDt.info.length > 0 ? <><strong>{info}</strong><br /></> : null}

                                {price_old > 0 ?
                                    <CurrencyFormat value={price_old} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="me-2 text-muted fst-italic text-decoration-line-through">{value}</span>} />
                                    : null
                                }

                                <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="fw-bold fs-6">{value}/{itemDt.product_unit}</span>} />

                                {price_old > 0 ? <span className='fw-bold fst-italic text-danger' >{parseInt((price_old - price) / price_old * 100) * (-1)}%</span> : null}
                            </Col>
                            <Col md={6} className='text-end'>
                                {cartItem.length === 0 ?
                                    <Button variant='outline-success' className='float-end'
                                        onClick={() => addToCart({
                                            id: itemDt.id,
                                            proName: product.product_name,
                                            proSlug: product.product_slug,
                                            proImg: product.product_image_main,
                                            proType: info,
                                            proUnit: itemDt.product_unit,
                                            price: price,
                                            priceOld: price_old,
                                        })
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                        <span className='btn-cart-text'>Thêm giỏ hàng</span>
                                    </Button>
                                    :
                                    <>
                                        <InputGroup className='justify-content-end flex-nowrap'>
                                            <Button variant='outline-secondary' onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) - 1)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Form.Control className='text-end' type='number' min="0" value={quantity} onChange={(e) => updateItemQuantity(itemDt.id, e.target.value)} style={{ maxWidth: '72px', minWidth: '72px' }} />
                                            <Button variant='outline-secondary' onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) + 1)}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </InputGroup>
                                    </>
                                }
                            </Col>
                        </Row>

                    </ListGroup.Item>
                );
                return itemDt;
            });
        }


        return (
            <Card>
                <Card.Body>
                    <Card.Title>{product.product_name || ''}</Card.Title>
                    <div className="product__details__rating">
                        <FontAwesomeIcon icon={faStar} className='text-warning' />
                        <FontAwesomeIcon icon={faStar} className='text-warning' />
                        <FontAwesomeIcon icon={faStar} className='text-warning' />
                        <FontAwesomeIcon icon={faStar} className='text-warning' />
                        <FontAwesomeIcon icon={faStar} className='text-warning' />
                        {/* <span>(18 reviews)</span> */}
                    </div>

                    <Card.Text className='product_desc' dangerouslySetInnerHTML={{ __html: product.product_desc || '' }} ></Card.Text>

                </Card.Body>

                <ListGroup className="list-group-flush">
                    {itemProDt}
                </ListGroup>
            </Card>
        );
    }

    const showProductContent = (e) => {
        const product__details__tab__desc = document.getElementById('product__details__tab__desc');
        const product__details__tab__btn = document.getElementById('product__details__tab__btn');

        let testClass = product__details__tab__desc.className;

        if (testClass === 'product__details__tab__desc card-text show') {
            product__details__tab__desc.classList.remove('show');
            product__details__tab__btn.innerHTML = 'Xem thêm';
        } else {
            product__details__tab__desc.classList.add('show');
            product__details__tab__btn.innerHTML = 'Thu gọn';
        }
    }



    const imageProduct = () => {
        const images = [
            {
                original: product.product_image_main !== null ? product.product_image_main : '/assets/images/picture.png',
                thumbnail: product.product_image_main !== null ? product.product_image_main : '/assets/images/picture.png',
            },
        ];

        const product_img_sub_ = product.product_img_sub_ ? product.product_img_sub_ : [];

        if (product_img_sub_.length > 0) {
            product_img_sub_.map(itemImg => {
                if (itemImg !== null) {
                    images.push({
                        original: itemImg,
                        thumbnail: itemImg,
                    });
                }
                return itemImg;
            })
        }

        const productDetails = product.product_detail ? product.product_detail : [];

        if (productDetails.length > 0) {
            productDetails.map((itemDt, iDt) => {
                if (itemDt.product_image !== null) {
                    images.push({
                        original: itemDt.product_image,
                        thumbnail: itemDt.product_image,
                    });
                }
                return itemDt;
            });
        }

        return <ImageGallery items={images} />
    }

    return (
        <>
            <IsLoading show={isLoad} />

            <div>
                <BreadcrumbView link={[
                    { name: 'Trang chủ', active: false, href: `${props.page === 'order' ? '/order/' : '/'}` },
                    { name: product.category_name || `Danh mục`, active: false, href: `${props.page === 'order' ? '/order' : ''}/danh-muc/${product.category_slug}` },
                    { name: product.product_name || `Sản phẩm`, active: true },
                ]} />

                <Row className='featured_product'>
                    <Col md={12} >
                        <Card >
                            <Card.Body>
                                <Row>
                                    <Col md={6} className='featured_product_img py-0'>
                                        <Card className='img_main'>
                                            {imageProduct()}
                                        </Card>
                                    </Col>

                                    <Col md={6} className='featured_product_info py-0'>
                                        {productInfo()}
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={12} className='product__details__tab'>
                        <Card >
                            <Card.Header>
                                <Nav variant="tabs" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="#first" className='fw-bold'>Chi tiết sản phẩm</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                {product.product_content !== null ?
                                    <>
                                        <Card.Text className='product__details__tab__desc' id="product__details__tab__desc" >
                                            <span dangerouslySetInnerHTML={{ __html: product.product_content }}></span>
                                        </Card.Text>

                                        <div className='product__details__tab bg-article'>
                                            <Button variant="outline-primary" id="product__details__tab__btn" onClick={(e) => showProductContent(e)} data-show='show' >Xem thêm</Button>
                                        </div>
                                    </>
                                    : <h6 className='text-muted'>Không có thông tin...</h6>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <CartBubble page={props.page} />
            </div>
        </>
    )
}

export default ProductDetails;