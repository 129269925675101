import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Col, Row, Card, Breadcrumb, Container } from 'react-bootstrap';
import { Link, NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import './Blog.scss'
import IsLoading from '../../user/other/IsLoading';

const BlogIndex = () => {
    const { topicSlug } = useParams();
    const [posts, setPosts] = useState([]);
    const [topic, setTopic] = useState({
        topic_slug: '',
        topic_name: '',
        topic_image: ''
    });
    const [isLoad, setIsload] = useState(false);

    useEffect(() => {
        document.title = "Mỹ Kat | Tin tức";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getPostAll(topicSlug);
    }, [topicSlug]);

    const getImage = (src) => {
        if (src == null)
            return '/logo512.png';
        else
            return src
    }

    const getPostAll = async (topicSlug) => {
        setIsload(true);
        setTopic({
            topic_slug: '',
            topic_name: '',
            topic_image: ''
        });
        await axios.get(`/getPostsByTopicSlug/${topicSlug ? topicSlug : 'undefined'}`).then((res) => {
            setPosts(res.data.posts.data);
            if (res.data.topic != null) {
                setTopic(res.data.topic);
            }
            setIsload(false);
        }).catch((error) => {
            
            setIsload(false);
        });
    }

    const viewPosts = () => {
        let output = [];
        if (posts.length > 0) {
            for (let i = 0; i < posts.length; i++) {
                output[i] = (<Col md={i * 2 / 3 % 3 === 0 ? 6 : 3} key={`postItem_${posts[i].posts_slug}`}>
                    <Card >
                        <Card.Img variant="top" src={getImage(posts[i].posts_image)} alt='img' className='post_img' />
                        <Card.Body>
                            <Card.Title>
                                <Link to={`/bai-viet/${posts[i].posts_slug}`} className='text-titleVi'>
                                    {posts[i].posts_title}
                                </Link>
                            </Card.Title>

                            <Card.Text className='text_descVi'>
                                {posts[i].posts_desc}
                            </Card.Text>

                            <div className='cart-reviews'>
                                <Card.Link href="#">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </Card.Link>
                                <Card.Link href="#">
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                </Card.Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col >);

            }
        } else {
            output = <p className='text-muted'>Hiện tại chưa có bài viết!</p>;
        }

        return output;
    }

    return (
        <Container className='detail_bg bg-white' id="page_blog">
            <Breadcrumb className='py-2'>
                <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
                    Trang chủ
                </Breadcrumb.Item>

                <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/tin-tuc/" }}  >
                    Tin tức
                </Breadcrumb.Item>

                <Breadcrumb.Item active >
                    {topic.topic_name !== '' ? topic.topic_name : 'Tất cả'}
                </Breadcrumb.Item>
            </Breadcrumb>

            <Row className='bg-white'>
                {viewPosts()}
            </Row>

            <IsLoading show={isLoad} />
        </Container >
    )
}

export default BlogIndex;