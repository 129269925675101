import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getPostsToHome();
    }, []);

    const getPostsToHome = () => {
        axios.get('/getPostsToHome?limit=4').then((res) => {
            if (res.status === 200) {
                setPosts(res.data.posts);
            }
        }).catch((error) => {});
    }

    const viewPosts = () => {
        if (posts.length > 0) {
            return posts.map((item, i) => {
                return (
                    <Col sm={3} xs={6} key={`postItem${i}`}>
                        <Card >
                            <Card.Img variant="top" src={item.posts_image} />
                            <Card.Body>
                                <Card.Title className='limited-text'>
                                    <Link className='text-dark' to={`/bai-viet/${item.posts_slug}`}>
                                        {item.posts_title}
                                    </Link>
                                </Card.Title>
                                <Card.Text className='limited-text-3'>
                                    {item.posts_desc}
                                </Card.Text>
                                {/* <Button variant="outline-primary" onClick={() => navigate()}> Xem chi tiết &#8594;</Button> */}
                            </Card.Body>
                        </Card>
                    </Col >
                )
            })
        }
    }

    return (<>
        {posts.length > 0
            ?
            <div className='home-posts'>
                <div className='category mt-2'>
                    <FontAwesomeIcon icon={faBullhorn} className='me-1' /> Tin tức
                </div>

                <Row className='bg-white ms-0 me-0'>
                    {viewPosts()}
                </Row>

                <center className='py-4'>
                    <button className='button-xemthem' onClick={() => navigate(`/tin-tuc/`)}>
                        Xem tất cả
                    </button>
                </center>
            </div>
            :
            null
        }
    </>
    )
}

export default Posts;




