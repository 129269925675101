import React, { useState, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useParams, Link } from 'react-router-dom';
import '../home/styles.css';
import { toast } from 'react-toastify';
import axios from "axios";
import { useCart } from "react-use-cart";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import CartBubble from '../cart/CartBubble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCartPlus, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import IsLoading from '../other/IsLoading';

$(window).scroll(function () {
  if ($(this).scrollTop() > 150) {
    $('#home_filter').addClass('position-fixed');
  } else {
    $('#home_filter').removeClass('position-fixed');
  }
})

function HomeIndex() {
  const [products, setProducts] = useState([]);
  const { current_page, per_page, total } = products;
  const { addItem, items, updateItemQuantity, updateCartMetadata, metadata, cartTotal } = useCart();

  const { category_slug } = useParams();
  const [keyword, setKeyword] = useState('');
  const [perPage, setPerPage] = useState(30);
  const [sort, setSort] = useState('name_asc');
  const [isLoad, setIsLoad] = useState(false);
  // const [categorySlug, setCategorySlug] = useState('');

  useEffect(() => {
    // if (categorySlug !== category_slug) {
    document.title = "Sản phẩm";
    window.scrollTo({ top: 0, behavior: 'smooth' });

    getProducts(category_slug);
    // }

  }, [category_slug]);

  const getProducts = (categorySlug = 'undefined', pageNumber = 1, perPages = perPage, search = '', sortType = sort) => {
    setIsLoad(true);
    axios.get(`/getProducts/${categorySlug}?page=${pageNumber}&per_page=${perPages}&search=${search}&sortType=${sortType}`).then((res) => {
      if (res.status === 200) {
        setProducts(res.data);
      }
      setIsLoad(false);
    }).catch((error) => {
      
      setIsLoad(false);

    });
  }

  const updateCart = () => {
    return updateCartMetadata({
      customer_name: '',
      customer_phone: '',
      customer_email: '',
      province_id: '',
      district_id: '',
      ward_id: '',
      customer_address: '',
      contract_desc: '',
      discount_code: '',
      discount_money: 0,
      discount_type: '',
      discount_value: '',
      totalMoney: cartTotal,
      error_list: [],
    });
  }

  const addToCart = (data) => {
    if (typeof metadata === 'undefined') {
      updateCart();
    } else if (Object.keys(metadata).length === 0) {
      updateCart();
    }

    addItem(data);
    toast('Đã thêm vào giỏ hàng.', { type: 'success', autoClose: 500 });
  }

  const productInterface = () => {
    const productList = products.data || [];

    if (productList.length > 0) {
      return productList.map((item, i) => {
        let itemPrice = [];

        if (item.product_detail.length > 0) {
          item.product_detail.map((itemDt, iDt) => {
            const price = parseInt(itemDt.product_price_sale) > 0 ? parseInt(itemDt.product_price_sale) : parseInt(itemDt.product_price);
            const price_old = parseInt(itemDt.product_price_sale) > 0 ? parseInt(itemDt.product_price) : 0;
            let info = '';

            let cartItem = items.filter((itemCart, i) => itemCart.id === itemDt.id);

            let quantity = cartItem.length > 0 ? cartItem[0].quantity : 0;

            if (itemDt.info.length > 0) {
              itemDt.info.map((itemIf, iIf) => {
                let firstchar = iIf === 0 ? '' : ' - ';
                info = info + firstchar + itemIf.info_name + ' ' + itemIf.info_dt_name;
                return itemIf
              });
            }

            itemPrice.push(
              <div className='item-price' key={`itemPrice${iDt}`}>
                {itemDt.info.length > 0 ? <div>{info}</div> : <></>}
                <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="fw-bold fs-6">{value}/{itemDt.product_unit}</span>} />
                {price_old > 0 ?
                  <div>
                    <CurrencyFormat value={price_old} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="me-2 text-muted fst-italic text-decoration-line-through">{value}</span>} />
                    <span className='fw-bold fst-italic text-danger' >{parseInt((price_old - price) / price_old * 100) * (-1)}%</span>
                  </div>
                  : null
                }

                <div className='item-btn'>
                  {cartItem.length === 0 ?
                    <Button variant='outline-success'
                      onClick={() => addToCart({
                        id: itemDt.id,
                        proName: item.product_name,
                        proSlug: item.product_slug,
                        proImg: item.image_main_link,
                        proType: info,
                        proUnit: itemDt.product_unit,
                        price: price,
                        priceOld: price_old,
                      })
                      }
                    >
                      <FontAwesomeIcon icon={faCartPlus} />
                      Thêm giỏ hàng
                    </Button>
                    :
                    <InputGroup className='justify-content-end flex-nowrap item-qty'>
                      <Button variant='outline-secondary' onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) - 1)} >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                      <Form.Control className='text-end' type='number' min="0" value={quantity} onChange={(e) => updateItemQuantity(itemDt.id, e.target.value)} />
                      <Button variant='outline-secondary' onClick={(e) => updateItemQuantity(itemDt.id, parseInt(quantity) + 1)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </InputGroup>
                  }
                </div>
              </div >
            );

            return itemDt;
          });
        }

        return (
          <Col key={`productItem${i}`} className='featured__item' lg={3} md={4} xs={6}>
            <div className='featured__item__pic'>
              <Link to={`/order/san-pham/${item.product_slug}`} title="Xem sản phẩm">
                {item.image_main_link !== null ? <img src={item.image_main_link} width='100%' alt={item.product_name} /> : null}
              </Link>
            </div>
            <div className='featured__item__text'>
              <Link className='product__item__title' to={`/order/san-pham/${item.product_slug}`} title="Xem sản phẩm">
                {item.product_name}
              </Link>

              <div className='product__item__price'>
                {itemPrice}
              </div>
            </div>
          </Col>
        )
      });
    } else {
      return <h5 className='text-muted'>Hiện tại chưa có sản phẩm!</h5>
    }
  }

  const handlePerPage = (event) => {
    setPerPage(Number(event.target.value));
    getProducts(category_slug, 1, Number(event.target.value), keyword);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleSearch = (e) => {
    e.preventDefault()
    getProducts(category_slug, 1, perPage, keyword);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleSort = (event) => {
    setSort(event.target.value);
    getProducts(category_slug, 1, perPage, keyword, event.target.value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (<>
    <IsLoading show={isLoad} />

    <div className="featured__product bg-white" >

      <Row className='home-filter pt-2' id="home_filter">
        <Col lg={3} md={4} sm={6} xs={12} className='pt-0 pb-2'>
          <Form onSubmit={handleSearch}>
            <InputGroup>
              <Form.Control defaultValue={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Tìm kiếm" />
              <Button variant='outline-secondary' type='submit'>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Form>
        </Col>

        <Col lg={{ span: 3, offset: 3 }} md={4} sm={3} xs={6} className='pt-0 pb-2'>
          <InputGroup>
            <InputGroup.Text >Xếp theo</InputGroup.Text>
            <Form.Select defaultValue={sort} className="form-select" onChange={(e) => handleSort(e)}  >
              <option value="id_desc">Mới nhất</option>
              <option value="sale_desc">Khuyến mãi</option>
              <option value="name_asc">A &#8594; Z</option>
              <option value="name_desc">Z &#8594; A</option>
              <option value="price_asc">Giá tăng dần</option>
              <option value="price_desc">Giá giảm dần</option>
            </Form.Select>
          </InputGroup>
        </Col>

        <Col lg={3} md={4} sm={3} xs={6} className='pt-0 pb-2'>
          <InputGroup>
            <InputGroup.Text >Hiển thị</InputGroup.Text>
            <Form.Select defaultValue={perPage} className="form-select" onChange={(e) => handlePerPage(e)}  >
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        {productInterface()}
      </Row>

      <Row>
        <Pagination
          activePage={current_page || 0}
          totalItemsCount={total || 0}
          itemsCountPerPage={Number(per_page || 0)}
          onChange={(pageNumber) => {
            getProducts(category_slug, pageNumber, perPage, keyword, sort);
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText="Đầu"
          lastPageText="Cuối"
        />
      </Row>

      <CartBubble page={`order`} />

    </div>
  </>)
}

export default HomeIndex;




